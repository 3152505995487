.MuiMenuItem-root {
  padding: 6px 16px 6px 16px !important;
  width: 100% !important;
  justify-content: left !important;
}

.MuiBadge-dot {
  padding: 0;
  margin-right: 4px;
  margin-top: 4px;
  z-index: auto;
  min-width: 10px;
  width: 10px !important;
  height: 10px !important;
  opacity: 0.99;
  background: #ff4181;
  border-radius: 100% !important;
}

.MuiDataGrid-root {
  padding: 0;
}

/* Don't put damn blue outline around focus. */
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell:focus {
  outline: none !important;
}

/* No un-sort icon. */
.MuiDataGrid-root
  .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted)
  .MuiDataGrid-sortIcon,
.MuiDataGrid-root
  .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover
  .MuiDataGrid-iconButtonContainer {
  opacity: 0;
}

/* Sort icons. */
.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiDataGrid-sortIcon {
  width: 1.75em;
  height: 1.5em;
  fill: rgb(0, 0, 0, 0.47);
  background-color: transparent;
}

/* More header settings. */
.MuiDataGrid-columnHeaders {
  max-height: 40px !important;
  min-height: 40px !important;
  height: 40px;
  line-height: 42px !important;
  padding: 0 !important;
  margin: 0;
  background-color: #f5f5f5;
  font-size: 16px;
  text-align: left;
  width: 100%;
  color: rgb(68, 68, 68);
}

.MuiDataGrid-columnHeader {
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  border-bottom: 0 !important;
  padding: 0 !important;
  border-right: 1px solid rgb(224, 224, 224);
}

.MuiDataGrid-cell {
  line-height: 40px !important;
  font-size: 0.875rem;
}

.MuiDataGrid-cell {
  display: inline-block !important;
  text-overflow: ellipsis !important;
}

.MuiDataGrid-columnHeader:not(:last-of-type) {
  border-right: 1px solid rgb(219, 219, 219);
}

.MuiDataGrid-columnsContainer {
  background: #f5f5f5 !important;
}

/* Don't put damn blue outline around focus. */
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell:focus {
  outline: none !important;
}

/* No un-sort icon. */
.MuiDataGrid-root
  .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted)
  .MuiDataGrid-sortIcon,
.MuiDataGrid-root
  .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover
  .MuiDataGrid-iconButtonContainer {
  opacity: 0;
}

.MuiDataGrid-columnHeader:focus {
  outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding-left: 8px !important;
}

@import 'palmetto-direflow-react-table.css';
/* Add project-specfic styles */
/* This file will contain project specific styling */
/* Any styling that can be used cross projects should be written in palmtto-ui-components package */

*,
*:before,
*:after {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #c6c5c5;
}
::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}

.MuiInputBase-input {
  /* height: 2em !important; */
}

/* Grid styling */

.body-cell {
  height: 43px;
  padding-left: 18px !important;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  /* color: var(--primary-text-color)  !important; */
  cursor: pointer !important;

  border-bottom: 1px solid #e0e0e0;
}

.body-header {
  padding-left: 18px;
  font-size: 16px !important;
  background: #f5f5f5 !important;
  font-weight: 500 !important;
  min-height: 42px !important;
  line-height: 0;
  box-sizing: border-box;
}

.form-margin {
  margin: 0 6px;
}

.assignment-card {
  width: 240px;
  margin: 6px;
  height: 84px;
}

.border {
  border: 1px solid #e0e0e0;
}

.cursor-pointer {
  cursor: pointer;
}

.display-none {
  display: none;
}

.padding-12 {
  padding: 12px;
}
.dropdown-margin-fix {
  margin-top: 0 !important;
}

a {
  text-decoration: none;
}

.barcode-scan-container {
  width: 800px;
  height: 600px;
}

#video {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

@media screen and (max-width: 769px) {
  .barcode-scan-container {
    width: 100%;
    height: 100%;
  }
}
